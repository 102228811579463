
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text, Heading } from "rebass/styled-components";
import Layout from "../components/Layout";
import Logo from "../components/common/Logo";
import Link from "../components/common/Link";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
function Custom404() {
    const { t } = useTranslation("error");
    return (<Layout withTransparentHeader immersive hideHeader title={t("notFoundError")}>
      <Flex alignItems="center" justifyContent="center" minHeight="100vh" textAlign="center" sx={{
            background: "url('/images/patterns/dog-hair.svg')",
            backgroundRepeat: "repeat",
            backgroundSize: "200px"
        }}>
        <Box bg="pugYellow" maxWidth="600px" minHeight={["100vh", 0]} padding="8em 4em">
          <Flex mb={4} justifyContent="center">
            <Logo color="samoyedWhite" height="60px" width="200px" name="logo"/>
          </Flex>

          <Heading as="h1" color="white" fontSize="40px" lineHeight="1.5em" mb={4}>
            {t("notFoundError")}
          </Heading>

          <Link href={{
            pathname: "/search"
        }}>
            <Button variant="primary" size="big" sx={{
            fontWeight: "bold",
            fontSize: 4
        }}>
              <Flex alignItems="center">
                <Box mr={1} mt={1}>
                  <Icon name="search" color="samoyedWhite"/>
                </Box>
                {t("findASitter")}
              </Flex>
            </Button>
          </Link>
        </Box>
      </Flex>
    </Layout>);
}
export default Custom404;

    async function __Next_Translate__getStaticProps__19565db7f02__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19565db7f02__ as getStaticProps }
  